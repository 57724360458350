<template>
  <div>
    <div style="padding: 100px 0;">
      <div style="text-align: center;">
        <img src="../assets/img/192x192.png"
             style="width: 100px;height: 100px;">
        <div style="color: #96c93d;font-size: 30px;text-align: center;font-weight: bold;">把把智能APP1.0</div>
      </div>
      <div style="text-align: center;margin: 20px 0;">
        <button @click="download('https://file.bbjt88.com/downapp/jzh.ipa')"
                style="margin: 0 auto; padding:10px 20px;background-color: #fff;border: 1px solid #000;border-radius: 5px;font-size: 20px;width: 250px;display: flex;align-items: center;justify-content: center;">
          <svg t="1692354127804"
               class="icon"
               viewBox="0 0 1084 1024"
               version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="4007"
               width="48"
               height="48">
            <path d="M821.235026 544.015116c-1.287001-129.729651 105.791442-191.891776 110.553344-194.980577-60.102924-88.030835-153.796561-100.12864-187.258574-101.54434-79.794032-7.979403-155.598362 46.975519-196.010178 46.975519-40.411816 0-102.831341-45.688518-168.983167-44.530218-86.872534 1.287001-167.052666 50.57912-211.840284 128.442651-90.347436 156.756662-23.166009 388.931554 64.864826 515.958505 42.985817 62.162125 94.337137 132.046252 161.647264 129.600951 64.864826-2.574001 89.317835-41.956217 167.696167-41.956217s100.38604 41.956217 169.111867 40.669216c69.755428-1.287001 114.028245-63.449125 156.756662-125.86865 49.42082-72.200729 69.755428-142.084856 70.913728-145.559758C957.14228 750.449998 822.522026 698.969977 821.235026 544.015116L821.235026 544.015116 821.235026 544.015116zM692.277575 163.449065c35.778614-43.371917 59.845524-103.474841 53.281821-163.449065-51.48002 2.059201-113.899545 34.234214-150.83646 77.477431C561.647023 115.958746 532.560811 177.21997 540.411514 235.907194 597.811737 240.411695 656.49896 206.692282 692.277575 163.449065L692.277575 163.449065 692.277575 163.449065z"
                  p-id="4008"></path>
          </svg>
          <span style="margin-left: 10px;">苹果下载</span></button>
      </div>
      <div style="text-align: center;margin: 20px 0;">
        <button @click="download('https://file.bbjt88.com/downapp/jzh.apk')"
                style="margin: 0 auto; padding:10px 20px;background-color: #fff;border: 1px solid #a4c439;border-radius: 5px;font-size: 20px;width: 250px;display: flex;align-items: center;justify-content: center;">
          <svg t="1692354886914"
               class="icon"
               viewBox="0 0 1024 1024"
               version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="4053"
               width="48"
               height="48">
            <path d="M157.947546 735.493119c-45.735653 0-82.696442-37.226694-82.696442-82.696443v-253.141521c0-45.469748 36.960789-82.696442 82.696442-82.696443 22.070112 0 42.810699 8.508959 58.499091 24.197351 15.688393 15.688393 24.197351 36.428979 24.197352 58.499092v253.141521c0 22.070112-8.508959 42.810699-24.197352 58.499091-15.688393 15.422488-36.428979 24.197351-58.499091 24.197352"
                  fill="#FFFFFF"
                  p-id="4054"></path>
            <path d="M157.947546 339.028824c-33.504025 0-60.360426 27.122306-60.360426 60.360426v253.141521c0 33.504025 27.122306 60.360426 60.360426 60.626331 33.504025 0 60.360426-27.122306 60.360426-60.626331v-253.141521c0-33.23812-27.122306-60.360426-60.360426-60.360426M806.489743 351.526357H217.244352v-22.336017c0-91.205401 49.724227-176.029083 131.091145-226.285121L316.692807 45.469748c-3.988574-6.647624-4.786289-15.422488-2.65905-23.133731 2.393145-7.711244 7.711244-14.624773 14.890678-18.347443C333.44482 1.329525 338.497014 0 343.549208 0c11.168008 0 21.538302 6.115814 26.856401 15.954298l33.23812 60.360425c34.30174-12.497533 70.464814-18.879252 107.957414-18.879252 38.024409 0 74.453389 6.381719 108.755128 19.145157L653.328486 15.954298c5.318099-9.838484 15.688393-15.954298 26.856401-15.954298 5.052194 0 10.104388 1.329525 14.624773 3.722669 7.445339 3.988574 12.763438 10.636198 14.890678 18.613348 2.393145 7.977149 1.329525 16.220203-2.65905 23.399636l-31.64269 57.701376C756.765515 153.693067 806.489743 238.516749 806.489743 329.72215v21.804207z"
                  fill="#FFFFFF"
                  p-id="4055"></path>
            <path d="M645.351337 111.680083l42.278889-77.112438c2.12724-3.988574 0.797715-9.306674-3.19086-11.433914-4.254479-2.12724-9.306674-0.797715-11.433913 3.456765l-42.544793 77.910153c-35.89717-15.954298-76.048818-24.995066-118.327708-24.729161-42.278889 0-82.430538 8.774864-118.061802 24.729161l-42.544793-77.644248c-2.12724-3.988574-7.445339-5.584004-11.433913-3.456765s-5.584004 7.445339-3.456765 11.433914l42.278889 77.112438C295.95222 154.756687 239.846274 236.123604 239.846274 329.72215h544.573357c-0.265905-93.864451-56.105947-175.231368-139.068294-218.042067m-257.396001 119.125422c-12.497533 0-22.867827-10.104388-22.867826-22.867826 0-12.497533 10.104388-22.867827 22.867826-22.867827 12.497533 0 22.867827 10.370293 22.867827 22.867827 0 12.497533-10.104388 22.867827-22.867827 22.867826m248.089328 0c-12.497533 0-22.867827-10.104388-22.867827-22.867826 0-12.497533 10.104388-22.867827 22.867827-22.867827 12.497533 0 22.867827 10.370293 22.867826 22.867827 0 12.497533-10.370293 22.867827-22.867826 22.867826"
                  fill="#FFFFFF"
                  p-id="4056"></path>
            <path d="M410.823163 1024c-45.469748 0-82.696442-36.960789-82.696443-82.696442v-111.680084h-21.804206c-23.133731 0-44.937938-9.040769-61.424046-25.260971-16.486108-16.486108-25.526876-38.024409-25.260971-61.424045V328.392625H804.894313v414.545833c0 47.862893-38.822124 86.685017-86.685017 86.685016h-21.804206v111.680084c0 45.469748-36.960789 82.696442-82.696443 82.696442-22.070112 0-42.810699-8.508959-58.499091-24.197351-15.688393-15.688393-24.197351-36.428979-24.197351-58.499091v-111.680084h-37.226695v111.680084c-0.265905 45.469748-37.492599 82.696442-82.962347 82.696442"
                  fill="#FFFFFF"
                  p-id="4057"></path>
            <path d="M241.973513 742.672553c0 35.631265 28.717736 64.614905 64.614906 64.614905h43.874318V941.303558c0 33.23812 27.122306 60.360426 60.360426 60.360425 33.504025 0 60.360426-27.122306 60.360426-60.62633v-134.0161h81.632822v134.0161c0 33.23812 27.122306 60.360426 60.360426 60.360425 33.504025 0 60.360426-27.122306 60.360426-60.360425v-134.0161h44.140223c35.631265 0 64.614905-28.983641 64.614906-64.614905V349.930927H241.707608l0.265905 392.741626zM866.052454 735.227214c-45.469748 0-82.696442-36.960789-82.696443-82.696443v-253.141521c0-45.735653 36.960789-82.696442 82.696443-82.696443 45.735653 0 82.696442 36.960789 82.696442 82.696443v253.141521c0 45.735653-36.960789 82.696442-82.696442 82.696443"
                  fill="#FFFFFF"
                  p-id="4058"></path>
            <path d="M866.052454 339.028824c-33.504025 0-60.360426 27.122306-60.360426 60.360426v253.141521c0 33.504025 27.122306 60.360426 60.360426 60.360426 33.504025 0 60.360426-27.122306 60.360426-60.360426v-253.141521c0-33.23812-26.856401-60.360426-60.360426-60.360426"
                  fill="#FFFFFF"
                  p-id="4059"></path>
            <path d="M157.947546 339.028824c-33.504025 0-60.360426 27.122306-60.360426 60.360426v253.141521c0 33.504025 27.122306 60.360426 60.360426 60.360426 33.504025 0 60.360426-27.122306 60.360426-60.360426v-253.141521c0-33.23812-27.122306-60.360426-60.360426-60.360426M645.351337 111.680083l42.278889-77.112438c2.12724-3.988574 0.797715-9.040769-3.19086-11.433914-3.988574-2.12724-9.306674-0.797715-11.433913 3.456765l-42.544793 77.910153c-35.89717-15.954298-76.048818-24.995066-118.593612-24.995066-42.278889 0-82.430538 8.774864-118.061803 24.729161l-42.544793-77.644248c-2.12724-3.988574-7.445339-5.584004-11.433913-3.456765s-5.584004 7.445339-3.456765 11.433914l42.278889 77.112438c-82.962347 42.810699-139.068294 124.177616-139.068294 217.776162h544.573357c0-93.598546-55.840042-174.965464-138.802389-217.776162m-257.396001 119.125422c-12.497533 0-22.867827-10.104388-22.867826-22.867826 0-12.497533 10.104388-22.867827 22.867826-22.867827 12.497533 0 22.867827 10.370293 22.867827 22.867827 0 12.497533-10.104388 22.867827-22.867827 22.867826m248.089328 0c-12.497533 0-22.867827-10.104388-22.867827-22.867826 0-12.497533 10.104388-22.867827 22.867827-22.867827 12.497533 0 22.867827 10.370293 22.867826 22.867827 0 12.497533-10.370293 22.867827-22.867826 22.867826m-394.337056 119.657232v392.475721c0 35.631265 28.717736 64.614905 64.614906 64.614905h43.874318v134.016099c0 33.23812 27.122306 60.360426 60.360426 60.360426 33.504025 0 60.360426-27.122306 60.360426-60.62633v-134.0161h81.632822V941.303558c0 33.23812 27.122306 60.360426 60.360426 60.360425 33.504025 0 60.360426-27.122306 60.360426-60.360425v-134.0161h44.140223c35.631265 0 64.614905-28.717736 64.614906-64.614905V350.196832l-540.318879 0.265905z m684.971177 48.926513c0-33.504025-27.122306-60.360426-60.360426-60.360426-33.504025 0-60.360426 27.122306-60.360426 60.360426v253.141521c0 33.504025 27.122306 60.360426 60.360426 60.360426 33.504025 0 60.360426-27.122306 60.360426-60.360426v-253.141521z"
                  fill="#A4C439"
                  p-id="4060"></path>
          </svg>
          <span style="margin-left: 10px;color: #a4c439;">安卓下载</span></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    download (url) {
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
  }
}
</script>

<style>
</style>
